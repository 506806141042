import $ from 'jquery'
import { SectionManager } from '../../common/section_manager'
import { CountdownSection } from '../countdown'
import './index.scss'

class HeroCountdownSection extends CountdownSection {
  static initClass() {
    this.type = 'hero_countdown'
  }
}
HeroCountdownSection.initClass()

$(function () {
  new SectionManager([HeroCountdownSection])
})
